<template>
  <div>
    <Main style="margin-top: 2rem;">
      <ParticipantsTable :generateReport="dataView.generateReport" />
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { useStore } from 'vuex';
import { defineAsyncComponent, reactive } from 'vue';

const ParticipantsTable = defineAsyncComponent(() => import('./overview/subevents/SubeventParticipantsOverview.vue'));

export default {
  name: 'SubeventParticipants',
  components: {
    Main,
    ParticipantsTable,
  },
  setup() {
    const { dispatch } = useStore();
    dispatch('changeTitleHeader', "Visualización de Participantes");

    const dataView = reactive({
      generateReport: false,
    });

    const handleGenerateReport = () => {
      dataView.generateReport = !dataView.generateReport;
    }

    return {
      dataView,
      handleGenerateReport
    };
  },
};
</script>
<style scoped>
.box-event {
  border: 1px solid #bcbdbe;
  border-radius: 7px;
  padding: 0 !important;
  margin-left: 0.5rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
}
.btn-add-event {
  background: #e70e4c;
  color: #fff;
  border-radius: 6px;
  border: none;
}
.btn-without {
  background: #fff;
  color: #e70e4c;
  border: none;
}
button:hover {
  cursor: pointer;
}
.title-more-events {
  color: #e70e4c;
  font-size: 14px;
  font-weight: bold;
}
.title-more-events:hover {
  cursor: pointer;
}
</style>
